import React from 'react';
import { PanelProps, GrafanaTheme } from '@grafana/data';
import { SimpleOptions } from 'types';
import { css } from 'emotion';
import { stylesFactory, useTheme } from '@grafana/ui';
import nlogo from './img/nikon-logo.svg';

interface Props extends PanelProps<SimpleOptions> {}

// const helpOptions = [
//   { value: 0, label: '次プロ', href: 'https://ngpd.nikon.com' },
//   // { value: 1, label: 'Tutorials', href: 'https://ngpd.nikon.com' },
//   // { value: 2, label: 'Community', href: 'https://ngpd.nikon.com' },
//   // { value: 3, label: 'Public Slack', href: 'https://ngpd.nikon.com' },
// ];

export const HeaderPanel: React.FC<Props> = ({ options, data, width, height }) => {
  const styles = getStyles(useTheme());

  return (
    <div className={styles.container}>
      {/* <a href="https://jp.nikon.com"><img src={nlogo} alt="株式会社ニコン" width="45" /></a> */}
      <img src={nlogo} alt="株式会社ニコン" width="45" style={{opacity: 0.0}} />
      <h1 className={styles.title}>Nikon Data Integration Platform</h1>
      <div className={styles.help}>
        {/* <div className={styles.helpLinks}>
          {helpOptions.map((option, index) => {
            return (
              <a
                key={`${option.label}-${index}`}
                className={styles.helpLink}
                // href={`${option.href}?utm_source=grafana_gettingstarted`}
                href={`${option.href}`}
                >
                {option.label}
              </a>
            );
          })}
        </div> */}
      </div>
    </div>
  );
};

const getStyles = stylesFactory((theme: GrafanaTheme) => {
  return {
    container: css`
      display: flex;
      /// background: url(public/img/g8_home_v2.svg) no-repeat;
      background-size: cover;
      background-color: #21262D;
      color: #BFC3C7;
      height: 100%;
      align-items: center;
      padding: 0 16px;
      justify-content: space-between;
      padding: 0 ${theme.spacing.lg};
      // overflow: auto; // in the scroll view.
      
      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        background-position: 0px;
        // flex-direction: column;
        // align-items: flex-start;
        // justify-content: center;
      }

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        padding: 0 ${theme.spacing.sm};
      }
    `,
    title: css`
      margin-bottom: 0;

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        margin-bottom: ${theme.spacing.sm};
      }

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        font-size: ${theme.typography.heading.h2};
      }
      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        font-size: ${theme.typography.heading.h3};
      }
    `,
    help: css`
      display: flex;
      align-items: baseline;
    `,
    helpText: css`
      margin-right: ${theme.spacing.md};
      margin-bottom: 0;

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        font-size: ${theme.typography.heading.h4};
      }

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        display: none;
      }
    `,
    helpLinks: css`
      display: flex;
      flex-wrap: wrap;
    `,
    helpLink: css`
      margin-right: ${theme.spacing.md};
      color: #BFC3C7;
      text-decoration: underline;
      text-wrap: no-wrap;

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        margin-right: 8px;
      }
    `,
  };
});
